var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * Class to authenticate Headless Form API
 */
export class FormAuthenticate {
    constructor(formAuthConfig) {
        this._formAuthConfig = formAuthConfig;
    }
    /**
     * Function to login
     * @param username
     * @param password
     * @returns Access token string
     */
    login(username, password) {
        let payload = {
            username,
            password,
            "grant_type": this._formAuthConfig.grantType,
            "client_id": this._formAuthConfig.clientId
        };
        let requestInit = {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: this._convertObjectToFormBody(payload)
        };
        return new Promise((resolve, reject) => {
            fetch(this._formAuthConfig.authBaseUrl, requestInit)
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                if (response.ok) {
                    let json = yield response.json();
                    resolve(json["access_token"]);
                }
                else {
                    reject(response);
                }
            }))
                .catch((error) => {
                reject(error);
            });
        });
    }
    /**
     * Private function to convert object to x-www-form-urlencoded
     * @param payload
     * @returns
     */
    _convertObjectToFormBody(payload) {
        let formBody = [];
        for (var property in payload) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(payload[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        return formBody.join("&");
    }
}
