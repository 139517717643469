import { isNull, isNullOrEmpty, isNumeric } from "./utils";
/**
 * Get a default value of element. The value can be predefinedValue, the first value of autofill data, or values of items are checked.
 * @param element The form element to get default value
 * @returns A string of default value
 */
export function getDefaultValue(element) {
    var _a;
    const dataProps = element.properties;
    const autoFillData = (_a = dataProps === null || dataProps === void 0 ? void 0 : dataProps.forms_ExternalSystemsFieldMappings) !== null && _a !== void 0 ? _a : [];
    let defaultValue = !isNullOrEmpty(dataProps === null || dataProps === void 0 ? void 0 : dataProps.predefinedValue)
        ? dataProps.predefinedValue
        : autoFillData.length > 0
            ? autoFillData[0]
            : undefined;
    //check if element is Choice
    const selectionProps = element.properties;
    if (!isNull(selectionProps === null || selectionProps === void 0 ? void 0 : selectionProps.items)) {
        let selectedArr = [];
        selectionProps.items.forEach(i => i.checked && selectedArr.push(i.value));
        if (selectedArr.length > 0) {
            defaultValue = selectedArr.join(",");
        }
    }
    //check if element is range
    const rangeProps = element.properties;
    if (!isNull(rangeProps === null || rangeProps === void 0 ? void 0 : rangeProps.min)) {
        if (isNullOrEmpty(defaultValue) || !isNumeric(defaultValue)) {
            defaultValue = rangeProps.min;
        }
        else {
            let rangeValue = parseInt(defaultValue);
            defaultValue = rangeValue > rangeProps.max || rangeValue < rangeProps.min ? rangeProps.min : rangeValue;
        }
    }
    return defaultValue;
}
