/**
 * Class to help save data to Storage. Default is sessionStorage.
 */
export class FormCache {
    constructor(storage) {
        this._storage = storage !== null && storage !== void 0 ? storage : window.sessionStorage;
    }
    get(key) {
        let data = this._storage[key];
        if (!data) {
            return undefined;
        }
        if (typeof data === "object") {
            data = JSON.parse(data);
        }
        if (!data) {
            return undefined;
        }
        return data;
    }
    set(key, data) {
        try { // safari private mode does not allow local storage
            if (typeof data === "object") {
                this._storage.setItem(key, JSON.stringify(data));
            }
            else {
                this._storage.setItem(key, data);
            }
        }
        catch (e) {
            console.log("Local Storage not supported: " + e.message);
        }
        return data;
    }
    remove(key) {
        this._storage.removeItem(key);
    }
}
