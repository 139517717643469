import { equals } from "../helpers";
import { newUniqueID } from "../helpers/generateId";
/**
 * Class to assign elements into step
 */
export class StepBuilder {
    constructor(form) {
        this._form = form;
    }
    /**
     * Function to assign elements into step
     * @returns Form with a list of steps and elements that are matched
     */
    buildForm() {
        let steps = [];
        let elements = [];
        let currentStep = { key: newUniqueID() };
        this._form.formElements.forEach((e, i) => {
            if (this.isFormStep(e)) {
                if (i !== 0) {
                    //if element is form step, then save list element to current step
                    steps.push({ formStep: Object.assign({}, currentStep), elements: [...elements] });
                }
                //reset vars
                elements = [];
                currentStep = Object.assign({}, e);
            }
            elements.push(e);
        });
        //if there is only one step, save list element to step
        steps.push({ formStep: Object.assign({}, currentStep), elements: [...elements] });
        return Object.assign(Object.assign({}, this._form), { steps });
    }
    /**
     * Check if element is FormStep
     * @param element Element to check
     * @returns True or false.
     */
    isFormStep(element) {
        return equals(element.contentType, "FormStepBlock");
    }
}
