import { equals, isNull } from "../helpers";
import { ConditionCombinationType } from "../models";
import { ConditionFunctions } from "./ConditionFunctions";
/**
 * Class to check if a element conditions is met
 */
export class FormDependConditions {
    constructor(element) {
        /**
         * Main function to check if a element conditions is met
         * @param formSubmissions
         * @returns
         */
        this.checkConditions = (formSubmissions) => {
            var _a;
            if (!isNull(formSubmissions)) {
                const conditionProps = this._element.properties;
                if (isNull(conditionProps === null || conditionProps === void 0 ? void 0 : conditionProps.conditions)) {
                    // no condition to check, return true
                    return true;
                }
                for (let i = 0; i < conditionProps.conditions.length; i++) {
                    const condition = conditionProps.conditions[i];
                    const fieldValue = (_a = formSubmissions.filter(s => equals(s.elementKey, condition.field))[0]) === null || _a === void 0 ? void 0 : _a.value;
                    const conditionFunction = ConditionFunctions[condition.operator];
                    if (!isNull(conditionFunction)) {
                        let checkResult = conditionFunction(fieldValue == null ? "" : fieldValue.toString(), condition.fieldValue);
                        if (conditionProps.conditionCombination === ConditionCombinationType.Any && checkResult) {
                            return true;
                        }
                        if (conditionProps.conditionCombination !== ConditionCombinationType.Any && !checkResult) {
                            return false;
                        }
                    }
                }
                // When reach here, there are two cases
                // 1 : All conditions are statisfied and ConditionCombination === ConditionCombinations.All
                // 2 : No condition is statisfied and ConditionCombination === ConditionCombinations.Any
                return !(conditionProps.conditionCombination === ConditionCombinationType.Any);
            }
            return false;
        };
        this._element = element;
    }
}
