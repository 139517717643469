import { equals, isNull, isNullOrEmpty } from "./utils";
/**
 * Class for step function helpers
 */
export class StepHelper {
    constructor(form) {
        this._tempBaseUrl = "http://temp";
        this._form = form;
    }
    /**
     * Verify this Form has all steps not attached to any page.
     * @returns true if no steps attached to any page.
     */
    isAllStepsAreNotLinked() {
        return !this._form.steps.some(s => { var _a; return !isNullOrEmpty((_a = s.formStep.properties) === null || _a === void 0 ? void 0 : _a.attachedContentLink); });
    }
    /**
     * Check whether a Form has steps each attached to a page.
     * @returns true if there is a step does not attach to any page but others do.
     */
    isMalFormSteps() {
        let isMalform = false;
        let totalStep = this._form.steps.length;
        if (totalStep >= 2 && !this.isAllStepsAreNotLinked()) {
            isMalform = this._form.steps.some(s => { var _a; return isNullOrEmpty((_a = s.formStep.properties) === null || _a === void 0 ? void 0 : _a.attachedContentLink); });
        }
        return isMalform;
    }
    /**
     * Get current step index depend on current page url
     * @param currentPageUrl
     * @returns
     */
    getCurrentStepIndex(currentPageUrl) {
        let currentStepIndex = 0;
        if (this.isAllStepsAreNotLinked()) {
            currentStepIndex = 0;
        }
        else {
            this._form.steps.every((s, i) => {
                var _a;
                let url = new URL((_a = s.formStep.properties) === null || _a === void 0 ? void 0 : _a.attachedContentLink, this._tempBaseUrl);
                let pageUrl = new URL(currentPageUrl !== null && currentPageUrl !== void 0 ? currentPageUrl : "/", this._tempBaseUrl);
                if (equals(pageUrl.pathname, url.pathname)) {
                    currentStepIndex = i;
                    return false;
                }
                return true;
            });
        }
        return currentStepIndex;
    }
    /**
     * Check whether element is in step
     * @param elementKey
     * @param stepIndex
     * @returns true if element is in step, otherwise false
     */
    isInCurrentStep(elementKey, stepIndex) {
        let currentStep = this._form.steps[stepIndex];
        if (currentStep) {
            return currentStep.elements.some(e => equals(e.key, elementKey));
        }
        return true;
    }
    /**
     * Get the first elementKey in the list element that are invalid value
     * @param formValidationResults
     * @param stepIndex
     * @returns
     */
    getFirstInvalidElement(formValidationResults, stepIndex) {
        var _a;
        return (_a = formValidationResults.filter(fv => !fv.result.valid &&
            this.isInCurrentStep(fv.elementKey, stepIndex))[0]) === null || _a === void 0 ? void 0 : _a.elementKey;
    }
    /**
     * Check whether a step is needed to check depend condition
     * @param stepIndex
     * @returns true if dependField property not null
     */
    isNeedCheckDependCondition(stepIndex) {
        var _a, _b, _c;
        let step = (_a = this._form.steps[stepIndex]) === null || _a === void 0 ? void 0 : _a.formStep;
        if (isNull(step)) {
            return false;
        }
        return !isNullOrEmpty((_c = (_b = step.properties) === null || _b === void 0 ? void 0 : _b.dependField) === null || _c === void 0 ? void 0 : _c.key);
    }
    /**
     * Get the first input elementKey in the list element
     * @param stepIndex
     * @returns
     */
    getFirstInputElement(stepIndex, inactiveElements) {
        var _a;
        const arrInputElement = this._form.steps[stepIndex].elements.find((e) => e.contentType.indexOf("ElementBlock") > 0 && inactiveElements.indexOf(e.key) < 0);
        return (_a = arrInputElement === null || arrInputElement === void 0 ? void 0 : arrInputElement.key) !== null && _a !== void 0 ? _a : "";
    }
}
